import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { adaptFetcherToSWRMutationFn } from 'shared/api/utils/adapt-fetcher-to-swr-mutation'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { PinPostInterface } from '../types/post-interface'

interface UseUnpinPostOptions {
  postId: number
  config?: SWRMutationConfiguration<void, Error, string, { data: PinPostInterface }>
}

export const getUnpinPostsKey = (postId: number): string => `/api/community/post/${postId}/pin`

export const useUnpinPost = ({ postId, config }: UseUnpinPostOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.delete>({
    method: RequestMethodsEnum.delete,
    badRequestHandler: () => {},
  })

  return useSWRMutation(
    getUnpinPostsKey(postId),
    adaptFetcherToSWRMutationFn<void, { data: PinPostInterface }>(fetcher),
    config,
  )
}
