import { useRollbar } from '@rollbar/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { KeyedMutator } from 'swr'
import { InternalError } from 'shared/errors/internal-error'
import * as postsApi from 'modules/community/api/posts-api'
import { PostCommentInfoResult } from 'modules/community/types/post-info'
import { PostInterface } from 'modules/community/types/post-interface'

interface UseCommentsInfoParams {
  postIds?: number[]
}

export default function useCommentsInfo({ postIds }: UseCommentsInfoParams) {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const [commentsInfoMap, setCommentsInfoMap] = useState<Record<number, PostCommentInfoResult>>({})

  const loadPostsInfo = useCallback(
    async (postIds: number[]) => {
      const newPostIds = postIds.filter(id => !commentsInfoMap[id])
      if (!newPostIds.length) return

      try {
        const { data } = await postsApi.getPostsCommentsInfo(newPostIds)
        setCommentsInfoMap(prev => {
          const updated = { ...prev }
          data.items.forEach(item => {
            updated[item.postId] = { ok: true, value: item }
          })

          newPostIds.forEach(id => {
            if (!updated[id]) {
              updated[id] = { ok: false }
            }
          })
          return updated
        })
      } catch (error) {
        if (error instanceof InternalError) {
          rollbar.error(error)
        } else {
          rollbar.log(JSON.stringify(error))
        }

        setCommentsInfoMap(prev => {
          const updated = { ...prev }
          newPostIds.forEach(id => {
            updated[id] = { ok: false, error: error as Error }
          })
          return updated
        })

        console.log(`--e--`, error)
      }
    },
    [rollbar, t],
  )

  useEffect(() => {
    if (!!postIds?.length) {
      loadPostsInfo(postIds)
    }
  }, [postIds?.join(',')])

  return commentsInfoMap
}
