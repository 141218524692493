import { createContext, useContext } from 'react'
import { PostCommentInfoResult } from '../types/post-info'

export interface PostCommentsInfoContextValue {
  commentsInfoMap: Record<number, PostCommentInfoResult>
}

const PostCommentsInfoContext = createContext<PostCommentsInfoContextValue | undefined>(undefined)

export const PostCommentsInfoProvider: React.FC<{
  children: React.ReactNode
  commentsInfoMap: Record<number, PostCommentInfoResult>
}> = ({ children, commentsInfoMap }) => {
  return (
    <PostCommentsInfoContext.Provider value={{ commentsInfoMap }}>
      {children}
    </PostCommentsInfoContext.Provider>
  )
}

export const usePostCommentsInfoContext = () => {
  const context = useContext(PostCommentsInfoContext)
  if (!context) {
    throw new Error('usePostCommentsInfoContext must be used within a PostCommentsInfoProvider')
  }
  return context
}
