import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import { useTranslation } from 'next-i18next'
import { twJoin, twMerge } from 'tailwind-merge'
import { AudioPlayerAttachement } from 'modules/attachments/components/audio-player'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import AttachmentPreviewItem from 'modules/attachments/components/preview/preview-item'
import { TextWithMentions } from 'modules/community/components/editor/utils/deserealizeMentionToHTML'
import { PostPinnedHeader } from 'modules/community/components/pinned-post/components/post-pinned-header'
import { AttachmentTypeEnum } from 'modules/community/types/post-attachment'
import { PostInterface } from 'modules/community/types/post-interface'
import { getPrimaryAttachment } from 'modules/community/utils/attachments'

const MAX_CONTENT_LENGTH_MOBILE = 125
const POST_TITLE_LENGTH_LIMIT = 40

export interface PostAnnounceLayoutProps extends ComponentPropsWithoutRef<'div'> {
  headerTitle: React.ReactNode
  headerActions: React.ReactNode
  footerContent: React.ReactNode
  extraContent?: React.ReactNode
  postData: PostInterface
  isPinnedPost?: boolean
  isNeedTruncate?: boolean
}

export const PostAnnounceLayout = ({
  headerTitle,
  headerActions,
  footerContent,
  extraContent,
  postData,
  isPinnedPost,
  isNeedTruncate,
  ...props
}: PostAnnounceLayoutProps) => {
  const { t } = useTranslation()

  const extraTitleLength = postData.title.length - POST_TITLE_LENGTH_LIMIT
  const primaryAttachment = getPrimaryAttachment(postData.attachments)

  return (
    <PostAttachmentsProvider attachments={postData.attachments}>
      {isPinnedPost && <PostPinnedHeader post={postData} className="relative rounded-t-lg" />}
      <div
        className={twJoin(
          'mb-8 w-full cursor-pointer border border-[#ECEEF1] bg-white px-5 py-[25px] font-avertaPE shadow-[0px_4px_20px_0px_#F2F4F5] transition-shadow hover:shadow-[0px_4px_25px_0px_rgb(255,255,255)]',
          isPinnedPost ? 'rounded-b-lg' : 'rounded-lg',
        )}
        {...props}
      >
        <div className="mb-2 flex justify-between sm:mb-5">
          <div className="flex flex-wrap items-center gap-x-2.5">{headerTitle}</div>
          <div className="flex items-center">{headerActions}</div>
        </div>

        <div className="flex flex-row items-start justify-between gap-2">
          <div className="w-full">
            <h2 className="community-post-title pb-[15px] text-[22px]">{postData.title}</h2>
            <TextWithMentions
              className={twMerge(
                'preview-image-wrapper cursor-pointer overflow-hidden text-ellipsis break-all text-base text-darkblue/90',
              )}
              truncateLength={
                isNeedTruncate
                  ? extraTitleLength > 0
                    ? MAX_CONTENT_LENGTH_MOBILE - extraTitleLength
                    : MAX_CONTENT_LENGTH_MOBILE
                  : undefined
              }
              text={postData.content}
              mentions={postData.mentions}
            />
            {!!postData?.attachments?.length && (
              <>
                {postData.attachments.map(
                  attachment =>
                    attachment.type === AttachmentTypeEnum.Audio && (
                      <AudioPlayerAttachement
                        attachment={attachment}
                        height="50px"
                        width="100%"
                        className="mt-3"
                        key={attachment.dataFileId}
                      />
                    ),
                )}
              </>
            )}
            {isNeedTruncate && (
              <button className="pt-[15px] leading-none text-blue hover:underline">
                {t('post_announce.post_show_more')}
              </button>
            )}

            {!!primaryAttachment && primaryAttachment.type !== AttachmentTypeEnum.Audio && (
              <AttachmentPreviewItem
                className="block h-full w-full max-w-[350px] sm:h-52 sm:w-52 md:hidden"
                attachment={primaryAttachment}
                key={primaryAttachment.dataFileId}
              />
            )}

            {footerContent}
          </div>

          {!!primaryAttachment && primaryAttachment.type !== AttachmentTypeEnum.Audio && (
            <AttachmentPreviewItem
              className="min-w-fit max-md:hidden"
              attachment={primaryAttachment}
            />
          )}
        </div>
      </div>
      {extraContent}
    </PostAttachmentsProvider>
  )
}
