import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { Fragment } from 'react'
import { useRouter } from 'next/router'
import useDomainAssets from 'shared/hooks/use-domain-assets'
import useUser from 'shared/hooks/use-user'
import CloseThinIcon from 'shared/icons/close-thin-icon'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import { PostAnnounceSkeleton } from 'modules/community/components/post-announce/components/post-announce-skeleton'
import { PostView } from 'modules/community/components/post/components/post-view'
import { PostInterface } from 'modules/community/types/post-interface'

export interface BaseModalProps {
  className?: string
  opened: boolean
  onClose: () => void
  deletePost?: () => Promise<void>
  deletePostAndBanUser?: (removeRecentActivitiesChecked: boolean) => Promise<void>
  post: PostInterface
  handleLikeClick?: () => void
  initialCommentsCount?: number
  topicPath: string
  handleDeclinePostAndBanUser?: (removeRecentActivitiesChecked: boolean) => Promise<void>
}

function PostModal({
  className,
  onClose,
  opened,
  post,
  handleLikeClick,
  topicPath,
  initialCommentsCount,
  deletePost,
  deletePostAndBanUser,
  handleDeclinePostAndBanUser,
}: BaseModalProps) {
  const router = useRouter()
  const { communityPath } = router.query
  const { domainAssets } = useDomainAssets()
  const { user, loading: isUserLoading } = useUser()

  if (!user || isUserLoading) {
    return <PostAnnounceSkeleton />
  }

  return (
    <PostAttachmentsProvider attachments={post.attachments}>
      <Transition show={opened} as={Fragment}>
        <Dialog className="fixed z-[20]" open={opened} onClose={onClose}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/60 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full w-full items-end justify-center text-center sm:items-center">
              <div className="relative w-full max-w-[840px]">
                <button
                  className="absolute right-0 top-5 z-[1001] ml-[15px] mt-11 hidden items-center justify-center lg:flex xl:flex 2xl:flex"
                  onClick={onClose}
                >
                  <CloseThinIcon className="fill-white" />
                </button>
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel
                    className={`relative min-h-screen w-full max-w-[800px] transform bg-white text-left shadow-xl transition-all md:mt-16 md:min-h-fit md:rounded-lg ${
                      className || ''
                    }`}
                  >
                    <div className="sticky top-0 z-[100] flex h-12 w-full items-center border-b border-gray-300/70 bg-white px-10 py-2 md:hidden">
                      <div className="flex w-full min-w-0">
                        <div className="flex min-w-0 items-center gap-2 text-darkblue">
                          <button className="flex items-center justify-center" onClick={onClose}>
                            <CloseThinIcon className="fill-bluegray" />
                          </button>
                          <img
                            src={domainAssets?.logoUrl}
                            alt="logo"
                            className="h-6 w-6 rounded-full"
                            width={24}
                            height={24}
                          />
                          <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
                            {post.title}
                          </p>
                        </div>
                      </div>
                    </div>
                    <PostView
                      post={post}
                      handleDeletePost={deletePost}
                      handleLikeClick={handleLikeClick}
                      deletePostAndBanUser={deletePostAndBanUser}
                      handleDeclinePostAndBanUser={handleDeclinePostAndBanUser}
                      isLoading={false}
                    />
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </PostAttachmentsProvider>
  )
}

export default PostModal
