import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { LocaleEnum } from 'shared/enums/locale-enum'
import useUser from 'shared/hooks/use-user'
import { timeSince } from 'shared/utils/date-time/time-since'
import { handleWithoutPropagating } from 'shared/utils/handle-without-propagating'
import LikeButton from 'modules/community/components/like/like-button'
import { usePostCommentsInfoContext } from 'modules/community/context/post-comments-info'
import { PostStatusEnum } from 'modules/community/enums/post-status-enum'
import useCommentsInfo from 'modules/community/hooks/use-comments-info'
import { PostInterface } from 'modules/community/types/post-interface'
import { PostPinnedActions } from '../pinned-post/components/post-pinned-actions'
import { PostActionsMenu } from '../post/components/post-actions-menu'
import PostModal from '../post/post-modal'
import { PostAuthorInfo } from './author-info'
import PostCommentsInfo from './comments-info'
import { PostAnnounceLayout } from './components/post-announce-layout'

type PostAnnounceProps = {
  post: PostInterface
  deletePost: () => Promise<void>
  deletePostAndBanUser: (removeRecentActivitiesChecked: boolean) => Promise<void>
  handleLikeClick?: () => void
  className?: string
  isPinnedPost?: boolean
}

function PostAnnounce({
  post,
  deletePost,
  handleLikeClick,
  deletePostAndBanUser,
  isPinnedPost,
}: PostAnnounceProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { user } = useUser()
  const { commentsInfoMap } = usePostCommentsInfoContext()
  const { communityPath, topicPath: topicPathParam } = router.query
  const communityPathWithSlug = `/community/${communityPath}`
  const topicPath = `/community/${communityPath}/${post.topic.path}`
  const postDetailsPath = `${topicPath}/${post.path}`
  const [opened, setOpened] = useState(false)

  const handleModalOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    const isLink = target.tagName.toLowerCase() === 'a'

    if (!isLink) {
      if (window) {
        window.history.replaceState(null, '', postDetailsPath)
      }
      setOpened(true)
    }
  }

  const handleModalClose = () => {
    if (window) {
      window.history.replaceState(null, '', topicPathParam ? topicPath : communityPathWithSlug)
    }
    setOpened(false)
  }

  const commentsInfo = commentsInfoMap[post.id]
  const commentsCount = commentsInfo?.ok ? (commentsInfo.value?.numberOfComments ?? 0) : 0

  return (
    <PostAnnounceLayout
      postData={post}
      isPinnedPost={isPinnedPost}
      isNeedTruncate={true}
      onClick={handleModalOpen}
      headerTitle={
        <PostAuthorInfo author={post.author} userId={post.userId}>
          <div className="bluegray inline max-h-[20px] lg:block">
            <span className="text-lightgray-100">
              {timeSince(new Date(post.createdAt), {
                locale: user?.dashboardLocale ?? LocaleEnum.ENGLISH,
              })}
            </span>{' '}
            <span className="text-lightgray-100">{t('community.pages_on')}</span>{' '}
            <Link className="font-semibold text-[#142D63]" href={topicPath} shallow>
              #{post.topic.name}
            </Link>
          </div>
        </PostAuthorInfo>
      }
      headerActions={
        <>
          <PostPinnedActions isPinnedPost={isPinnedPost} post={post} />
          <PostActionsMenu
            post={post}
            deletePost={deletePost}
            deletePostAndBanUser={deletePostAndBanUser}
          />
        </>
      }
      footerContent={
        <>
          {post.status === PostStatusEnum.Approved && (
            <div className="mt-[7px] flex items-center gap-[2px]">
              {handleLikeClick && (
                <div onClick={handleWithoutPropagating()}>
                  <LikeButton
                    isLiked={post.isLiked}
                    likesCount={post.likesCount}
                    handleLikeClick={handleLikeClick}
                    postId={post.id}
                  />
                </div>
              )}
              <PostCommentsInfo info={commentsInfo} />
            </div>
          )}
        </>
      }
      extraContent={
        <>
          {opened && (
            <PostModal
              deletePost={deletePost}
              deletePostAndBanUser={deletePostAndBanUser}
              opened={opened}
              onClose={handleModalClose}
              post={post}
              handleLikeClick={handleLikeClick}
              topicPath={topicPath}
              initialCommentsCount={commentsCount}
            />
          )}
        </>
      }
    />
  )
}

export default PostAnnounce
