import { useCallback } from 'react'
import { KeyedMutator } from 'swr'
import * as postsApi from 'modules/community/api/posts-api'
import { PostInterface } from 'modules/community/types/post-interface'

export const useDeletePost = ({ mutatePosts }: { mutatePosts: KeyedMutator<PostInterface[]> }) => {
  return useCallback(
    (postId: number, posts: PostInterface[]) => async () => {
      await postsApi.deletePost(postId)
      const filteredPosts = posts.filter(p => p.id !== postId)
      await mutatePosts(filteredPosts, false)
    },
    [mutatePosts],
  )
}
