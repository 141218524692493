import { useCallback } from 'react'
import { KeyedMutator } from 'swr'
import * as postsApi from 'modules/community/api/posts-api'
import { ACTIVITY_CLEANUP_PERIOD_DURATION } from 'modules/community/const/post-consts'
import { BanPeriodUnitEnum } from 'modules/community/enums/member-enum'
import { useDeletePost } from 'modules/community/hooks/use-delete-post'
import { PostInterface } from 'modules/community/types/post-interface'

export const useDeletePostAndBanUser = ({
  mutatePosts,
  communityPath,
}: {
  mutatePosts: KeyedMutator<PostInterface[]>
  communityPath: string
}) => {
  const deletePost = useDeletePost({ mutatePosts })

  return useCallback(
    (postId: number, posts: PostInterface[], userId: number) =>
      async (removeRecentActivitiesChecked: boolean) => {
        await deletePost(postId, posts)()

        await postsApi.banUser(
          communityPath,
          userId,
          removeRecentActivitiesChecked
            ? {
                deleteRecentActivityOptions: {
                  periodUnit: BanPeriodUnitEnum.day,
                  periodDuration: ACTIVITY_CLEANUP_PERIOD_DURATION,
                },
              }
            : undefined,
        )
        if (removeRecentActivitiesChecked) await mutatePosts()
      },
    [mutatePosts],
  )
}
