import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { twMerge } from 'tailwind-merge'
import Confirmation from 'shared/components/confirmation-modal'
import useUser from 'shared/hooks/use-user'
import PinIcon from 'shared/icons/pin-icon'
import { handleWithoutPropagating } from 'shared/utils/handle-without-propagating'
import { usePinPost } from 'modules/community/api/use-pin-post'
import { usePinnedPosts } from 'modules/community/api/use-pinned-posts'
import useIsUserMember from 'modules/community/hooks/use-is-user-member'
import { PostInterface } from 'modules/community/types/post-interface'
import { PostPinnedToType } from 'modules/community/types/post-pinned-to-type'
import { hasPermissions } from 'modules/community/utils/abac-roles-permissions'

interface PostPinnedActionsProps {
  post: PostInterface
  isPinnedPost?: boolean
}

const isPinnedIn = (post: PostInterface, relevantPosts: PostInterface[], flag: PostPinnedToType) =>
  relevantPosts.some(pinnedPost => pinnedPost.id === post.id && pinnedPost.pinnedTo?.includes(flag))

export const PostPinnedActions = ({ isPinnedPost, post }: PostPinnedActionsProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { user } = useUser()
  const { member } = useIsUserMember()
  const { topicPath } = router.query
  const { mutate: mutatePosts, data: posts = [] } = useSWR<PostInterface[]>('posts')
  const { mutate: mutateTopicPosts, data: topicPosts = [] } = useSWR<PostInterface[]>('topic-posts')
  const getPinnedPostsQuery = usePinnedPosts({ config: { revalidateOnMount: true } })
  const pinPostMutation = usePinPost({ postId: post.id })
  const relevantPosts = topicPath ? topicPosts : posts

  const handlePinPost = async (postId: number, pinTo: 'community' | 'topic') => {
    try {
      await pinPostMutation.trigger({ source: pinTo })
      const mutateTarget = pinTo === 'community' ? mutatePosts : mutateTopicPosts
      const currentPosts = pinTo === 'community' ? posts : topicPosts
      const postToUpdate = currentPosts.find(p => p.id === postId)
      if (!postToUpdate) return
      const updatedPost = {
        ...postToUpdate,
        pinnedTo: Array.from(new Set([...(postToUpdate.pinnedTo || []), pinTo])),
      }
      mutateTarget(
        currentPosts.filter(p => p.id !== postId),
        false,
      )
      getPinnedPostsQuery.mutate(prev => [...(prev || []), updatedPost], { revalidate: true })
      mutatePosts()
      mutateTopicPosts()
      toast.success(t('community.actions.pin_post.success'))
    } catch (e) {
      toast.error(t('community.actions.pin_post.error'))
    }
  }

  const [isPostPinnable] = hasPermissions(user, 'posts', ['pin'], {
    post,
    member,
  })

  const isPinnedInCommunity = isPinnedIn(post, relevantPosts, 'community')
  const isPinnedInTopic = isPinnedIn(post, relevantPosts, 'topic')

  if (!isPostPinnable) return null

  return (
    <div className="flex items-center">
      {!isPinnedPost && (
        <Menu
          onClick={handleWithoutPropagating()}
          as="div"
          className="relative flex h-[30px] w-[30px]"
        >
          <MenuButton onClick={handleWithoutPropagating()}>
            <PinIcon width={15} height={15} className="text-lightgray-100 hover:text-blue" />
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              as="ul"
              anchor="bottom end"
              className={twMerge(
                'flex w-max min-w-[144px] max-w-sm flex-col rounded-[10px] bg-white px-5 py-3 shadow-[0px_4px_10px_0px_#00000040]',
              )}
            >
              <MenuItem as="li">
                <button
                  className="flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm text-darkblue focus:text-darkblue/70"
                  onClick={() => handlePinPost(post.id, 'community')}
                  disabled={isPinnedInCommunity}
                >
                  {isPinnedInCommunity && (
                    <PinIcon width={15} height={15} className="text-lightgray-100" />
                  )}
                  <span className="ml-auto">{t('community.actions.pin_post.home')}</span>
                </button>
              </MenuItem>

              <MenuItem as="li">
                <button
                  className="flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm text-darkblue focus:text-darkblue/70"
                  onClick={() => handlePinPost(post.id, 'topic')}
                  disabled={isPinnedInTopic}
                >
                  {isPinnedInTopic && (
                    <PinIcon width={15} height={15} className="text-lightgray-100" />
                  )}
                  <span className="ml-auto">{t('community.actions.pin_post.topic')}</span>
                </button>
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      )}
    </div>
  )
}
