import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { adaptFetcherToSWRMutationFn } from 'shared/api/utils/adapt-fetcher-to-swr-mutation'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { PinPostInterface } from '../types/post-interface'

interface UsePinPostOptions {
  postId: number
  config?: SWRMutationConfiguration<void, Error, string, PinPostInterface>
}

export const getPinPostsKey = (postId: number): string => `/api/community/post/${postId}/pin`

export const usePinPost = ({ postId, config }: UsePinPostOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.post>({
    method: RequestMethodsEnum.post,
    badRequestHandler: () => {},
  })

  return useSWRMutation(
    getPinPostsKey(postId),
    adaptFetcherToSWRMutationFn<void, PinPostInterface>(fetcher),
    config,
  )
}
