const PinIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9161 3.15033L7.82126 0.0831271C7.70954 -0.027709 7.52813 -0.027709 7.41641 0.0831271L7.40329 0.0961232C7.21352 0.284142 7.10896 0.534247 7.10896 0.800185C7.10896 0.971518 7.15288 1.13593 7.23448 1.28172L3.99623 4.03605C3.74839 3.81654 3.43207 3.6964 3.09702 3.6964C2.73597 3.6964 2.39657 3.83572 2.14135 4.08872L2.12193 4.10796C2.0101 4.21874 2.0101 4.39841 2.12193 4.50919L3.97601 6.3467L2.17026 8.13625C2.13413 8.17308 1.27941 9.04598 0.717488 9.74062C0.18236 10.402 0.0765369 10.5231 0.0710396 10.5294C-0.0283127 10.6415 -0.0228154 10.8106 0.083523 10.9165C0.13924 10.972 0.21271 11 0.286408 11C0.353578 11 0.42092 10.9767 0.475091 10.9298C0.479844 10.9257 0.599296 10.8228 1.27094 10.2891C1.97173 9.73222 2.8525 8.88509 2.89253 8.84644L4.69536 7.05973L6.45014 8.79883C6.50603 8.85427 6.57933 8.88197 6.65257 8.88197C6.72581 8.88197 6.79916 8.85427 6.85499 8.79883L6.87441 8.77959C7.12969 8.52665 7.27027 8.19022 7.27027 7.83246C7.27027 7.50041 7.14898 7.18691 6.92755 6.94129L9.70672 3.73198C9.85383 3.81285 10.0197 3.85638 10.1926 3.85638C10.461 3.85638 10.7133 3.75281 10.903 3.56467L10.9161 3.55168C11.028 3.44079 11.028 3.26111 10.9161 3.15033Z"
      fill="currentColor"
    />
  </svg>
)
export default PinIcon
