import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { twJoin, twMerge } from 'tailwind-merge'
import Confirmation from 'shared/components/confirmation-modal'
import useUser from 'shared/hooks/use-user'
import PinIcon from 'shared/icons/pin-icon'
import { usePinnedPosts } from 'modules/community/api/use-pinned-posts'
import { useUnpinPost } from 'modules/community/api/use-unpin-post'
import useIsUserMember from 'modules/community/hooks/use-is-user-member'
import { PostInterface } from 'modules/community/types/post-interface'
import { hasPermissions } from 'modules/community/utils/abac-roles-permissions'

interface PostPinnedHeaderProps {
  post: PostInterface
  className?: string
}

export const PostPinnedHeader = ({ post, className }: PostPinnedHeaderProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { user } = useUser()
  const { member } = useIsUserMember()
  const unpinMutation = useUnpinPost({ postId: post.id })
  const { mutate: mutatePosts, data: posts = [] } = useSWR<PostInterface[]>('posts')
  const { mutate: mutateTopicPosts, data: topicPosts = [] } = useSWR<PostInterface[]>('topic-posts')
  const getPinnedPostsQuery = usePinnedPosts({ config: { revalidateOnMount: true } })
  const [isPostUnpinnable] = hasPermissions(user, 'posts', ['unpin'], {
    post,
    member,
  })

  const isPinnedInCommunity = getPinnedPostsQuery.data?.some(
    pinnedPost => pinnedPost.id === post.id && pinnedPost.pinnedTo?.includes('community'),
  )

  const isPinnedInTopic = getPinnedPostsQuery.data?.some(
    pinnedPost => pinnedPost.id === post.id && pinnedPost.pinnedTo?.includes('topic'),
  )

  const handleUnpin = async (postId: number, deleteIn: 'community' | 'topic') => {
    try {
      await unpinMutation.trigger({
        data: { source: deleteIn },
      })
      const unpinnedPost = getPinnedPostsQuery.data?.find(item => item.id === postId)
      if (!unpinnedPost) return
      const newPinnedTo = unpinnedPost.pinnedTo?.filter(flag => flag !== deleteIn) || []
      const updatedPost = {
        ...unpinnedPost,
        pinnedTo: newPinnedTo.length ? newPinnedTo : undefined,
      }
      getPinnedPostsQuery.mutate(
        prev => (prev ? prev.filter(item => item.id !== postId) : []),
        false,
      )
      const mutateTarget = deleteIn === 'community' ? mutatePosts : mutateTopicPosts
      mutateTarget(prevPosts => {
        const updatedPosts = [...(prevPosts || []), updatedPost]
        return updatedPosts.sort((a, b) => b.id - a.id)
      }, false)

      await getPinnedPostsQuery.mutate()
      toast.success(t('community.actions.unpin_post.success'))
    } catch (error) {
      toast.error(t('core.error.internal_error_message'))
    }
  }

  return (
    <div
      className={twJoin(
        'text-AvertaPE flex h-[30px] items-center justify-between bg-blue px-5',
        className,
      )}
    >
      <div className="flex items-center justify-center">
        <PinIcon className="text-white" />
        <span className="ml-2 text-[15px] font-normal font-semibold text-white">
          {t('pinned.post_pinned_title')}
        </span>
      </div>
      <div>
        {isPostUnpinnable && (
          <Menu as="div" className="relative hover:text-blue">
            <MenuButton>
              <button className="text-sm font-normal text-white underline">
                {t('pinned.post_unpin_button')}
              </button>
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                as="ul"
                anchor="bottom end"
                className={twMerge(
                  'flex w-max min-w-[144px] max-w-sm flex-col rounded-[10px] bg-white px-5 py-3 shadow-[0px_4px_10px_0px_#00000040]',
                )}
              >
                {isPinnedInCommunity && (
                  <MenuItem as="li">
                    <button
                      className="flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm text-darkblue focus:text-darkblue/70"
                      onClick={() => handleUnpin(post.id, 'community')}
                    >
                      <PinIcon width={15} height={15} className="text-lightgray-100" />
                      {t('community.actions.unpin_post.home')}
                    </button>
                  </MenuItem>
                )}
                {isPinnedInTopic && (
                  <MenuItem as="li">
                    <button
                      className="flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm text-darkblue focus:text-darkblue/70"
                      onClick={() => handleUnpin(post.id, 'topic')}
                    >
                      <PinIcon width={15} height={15} className="text-lightgray-100" />
                      {t('community.actions.unpin_post.topic')}
                    </button>
                  </MenuItem>
                )}
              </MenuItems>
            </Transition>
          </Menu>
        )}
      </div>
    </div>
  )
}
